import { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Button2 } from "../../../components/Typography";
import { getColorByType } from "../utils";
import { EUserType } from "../types";
import styles from "./UserType.module.css";

const cx = classNames.bind(styles);

interface Props {
  type: EUserType;
}

export const UserType: FC<Props> = ({ type }) => {
  const { t } = useTranslation("users");
  return (
    <Button2 className={cx({ container: true, [getColorByType(type)]: true })}>
      <span className={styles.dot} />
      {t(type)}
    </Button2>
  );
};
