import { TFunction } from "i18next";
import { EUserType, IUser } from "./types";
import { UserType } from "./UserType";
import { UsersActions } from "./UsersActions";

export const getColorByType = (type: EUserType) => {
  if (type === EUserType.Admin || type === EUserType.SuperAdmin) {
    return "violet";
  }
  return "gray";
};

export const getColumns = (
  t: TFunction,
  onSetUserToEdit: (user: IUser) => void,
  onSetUserToRemove: (user: IUser) => void
) => {
  return [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("userType"),
      dataIndex: "userType",
      key: "userType",
      render: (type: EUserType) => {
        return <UserType type={type} />;
      },
    },
    {
      title: "",
      key: "more",
      render: (user: IUser) => {
        return (
          <UsersActions
            user={user}
            onSetUserToEdit={onSetUserToEdit}
            onSetUserToRemove={onSetUserToRemove}
          />
        );
      },
    },
  ];
};

export const userNamesTranslations = {
  "Order Data Access": "orderData",
  "Order Creation": "orderCreation",
  "Order Management": "orderManagement",
  "Replenishment Data Access": "replenishmentData",
  "Indexing Task Control": "indexingTaskControl",
  "Basic Replenishment Key Creation": "basicReplenishmentKeyCreation",
  "Advanced Replenishment Key Creation": "advancedReplenishmentKeyCreation",
  "Key Management": "keyManagement",
  "Product Portfolio Access": "productPortfolioAccess",
  "Client Configuration Management": "clientConfigurationManagement",
  "System Data Access": "systemDataAccess",
  "System State Management": "systemStateManagement",
  "Media Data Access": "mediaDataAccess",
  "Media Creation": "mediaCreation",
  "System Control Management": "systemControlManagement",
  "R-Stop Control": "rStopControl",
  "Product Metadata Management": "productMetadataManagement",
  "Product Portfolio Management": "productPortfolioManagement",
  "Product Portfolio Administration": "productPortfolioAdministration",
};
