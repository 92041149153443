import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "antd";
import { H3 } from "../../../components/Typography";
import { FormInstance } from "antd/lib/form/Form";
import { LMDropdown, LMNewInput } from "../../../components";
import { EUserType, IUserFormValues } from "../types";
import { useAppNotifications } from "../../../components/LMNotifications";
import { createUser } from "../api";
import styles from "./UserFormModal.module.css";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  form: FormInstance<IUserFormValues>;
}

export const CreateUserForm: FC<Props> = ({ onClose, onRefresh, form }) => {
  const { t } = useTranslation("users");

  const { contextHolder, success, error } = useAppNotifications();

  const onFinish = async (values: IUserFormValues) => {
    try {
      await createUser(values);
      onRefresh();
      form.resetFields();
      success(t("createSuccessMessage"));
      onClose();
    } catch (e: any) {
      error(t("createErrorMessage"));
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <H3 className={styles.title}>{t("addNew")}</H3>
        <Form
          layout={"vertical"}
          form={form}
          className={styles.form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t("name")}
                rules={[{ required: true, message: t("requiredMessage") }]}
              >
                <LMNewInput placeholder={t("namePlaceholder")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="email"
                label={t("email")}
                rules={[
                  { required: true, message: t("requiredMessage") },
                  {
                    required: true,
                    type: "email",
                    message: t("emailValidationMessage"),
                  },
                ]}
              >
                <LMNewInput placeholder={t("emailPlaceholder")} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={t("type")}
                name="userType"
                rules={[{ required: true, message: t("requiredMessage") }]}
              >
                <LMDropdown
                  items={Object.keys(EUserType).map((key) => ({
                    name: t(key),
                    value: key,
                  }))}
                  placeholder={t("pleaseSelect")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
