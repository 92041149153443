import { Modal } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LMMultiSelect } from "../../../components/LMMultiSelect";
import { RootState } from "../../../app/store";
import { LMNewButton } from "../../../components/LMNewButton";
import { useAppNotifications } from "../../../components/LMNotifications";
import styles from "./AssignUserModal.module.css";
import { assignUserToRole } from "../api";

interface Props {
  open: boolean;
  onCancel: () => void;
  onRefresh: () => void;
  roleId: string;
}

export const AssignUserModal: FC<Props> = ({
  open,
  onCancel,
  roleId,
  onRefresh,
}) => {
  const { t } = useTranslation("users");
  const [usersIds, setUsersIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { success, error, contextHolder } = useAppNotifications();

  const users = useSelector((state: RootState) => {
    return state.users.simpleList;
  });

  const handleSelectUsers = (value: string[]) => {
    setUsersIds(value);
  };

  const handleAssign = async () => {
    setLoading(true);
    try {
      for (const userId of usersIds) {
        await assignUserToRole(userId, roleId);
      }
      setLoading(false);
      success(t("usersAssignedSuccess"));
      onRefresh();
      onCancel();
    } catch {
      setLoading(false);
      error(t("usersAssignedError"));
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        width={400}
        footer={null}
        className={styles.container}
        title={t("assignUser")}
        onCancel={onCancel}
      >
        <div className={styles.content}>
          <LMMultiSelect
            value={usersIds}
            onChange={handleSelectUsers}
            placeholder={t("assignUserPlaceholder")}
            items={users.map((user) => ({
              name: user.userName,
              value: user.id,
            }))}
          />
          <div className={styles.buttons}>
            <LMNewButton
              color="default"
              onClick={onCancel}
              className={styles.button}
            >
              {t("cancel")}
            </LMNewButton>
            <LMNewButton
              onClick={handleAssign}
              disabled={usersIds.length === 0 || loading}
              className={styles.button}
            >
              {t("add")}
            </LMNewButton>
          </div>
        </div>
      </Modal>
    </>
  );
};
