import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LMNewButton } from "../../../components/LMNewButton";
import { IRolesListItem } from "../types";
import { userNamesTranslations } from "../utils";
import { Body2, H3 } from "../../../components/Typography";
import { IconPlus, IconTimes } from "../../../assets";
import { AssignUserModal } from "../AssignUserModal";
import styles from "./UserRoleItem.module.css";
import { removeUserFromRole } from "../api";
import { useAppNotifications } from "../../../components/LMNotifications";

interface Props {
  userRole: IRolesListItem;
  onRefresh: () => void;
}

export const UserRoleItem = ({ userRole, onRefresh }: Props) => {
  const { success, error, contextHolder } = useAppNotifications();
  const { t } = useTranslation("userRoles");
  const { t: tUsers } = useTranslation("users");
  const [open, setOpen] = useState(false);

  const roleKey =
    userNamesTranslations[
      userRole.role.name as keyof typeof userNamesTranslations
    ];

  if (!roleKey) return null;

  const roleText = t(`${roleKey}`, { returnObjects: true }) as {
    title: string;
    description: string;
  };

  const handleAssignModalOpen = () => {
    setOpen(true);
  };

  const handleRevokeUser = async (userId: string) => {
    try {
      await removeUserFromRole(userId, userRole.role.id);
      success(tUsers("revokeSuccess"));
      onRefresh();
    } catch {
      error(tUsers("revokeError"));
    }
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={styles["role-container"]}>
        <H3 className={styles.role}>{roleText.title}</H3>
        <Body2 className={styles.description}>{roleText.description}</Body2>
      </div>
      <div className={styles["users-container"]}>
        <div className={styles.users}>
          {userRole.users.map((user) => (
            <div className={styles.user} key={user.id}>
              <Body2 className={styles["user-name"]}>{user.userName}</Body2>
              <LMNewButton
                color="transparent"
                onClick={() => handleRevokeUser(user.id)}
              >
                {tUsers("revoke")} <IconTimes stroke="var(--color-black)" />
              </LMNewButton>
            </div>
          ))}
        </div>
        <LMNewButton
          className={styles["add-button"]}
          color="transparent"
          onClick={handleAssignModalOpen}
        >
          {tUsers("addUser")} <IconPlus stroke="var(--color-black)" />
        </LMNewButton>
      </div>
      <AssignUserModal
        open={open}
        onCancel={() => setOpen(false)}
        roleId={userRole.role.id}
        onRefresh={onRefresh}
      />
    </div>
  );
};
