import { INavigationItem, INavigationItemSimple } from "./types";
import {
  IconControlNav,
  IconHomeNav,
  IconMaintenanceNav,
  IconMonitoringNav,
  IconOrdersNav,
  IconProductsPortfolioNav,
  IconStoreNav,
  IconSystemNav,
  IconUsers,
} from "../../assets";

export const NAVIGATION_ITEMS: INavigationItem[] = [
  {
    route: "/",
    translationKey: "home",
    nestedItems: [],
    icon: <IconHomeNav />,
    access: "all",
  },
  {
    route: "/orders",
    translationKey: "orders",
    nestedItems: [],
    icon: <IconOrdersNav />,
    access: "all",
  },
  {
    route: "/system",
    translationKey: "system",
    nestedItems: [],
    icon: <IconSystemNav />,
    access: "all",
  },
  {
    route: "/maintenance/keys",
    translationKey: "maintenance",
    nestedItems: [],
    icon: <IconMaintenanceNav />,
    access: "all",
  },
  {
    route: "/monitoring",
    translationKey: "monitoring",
    nestedItems: [],
    icon: <IconMonitoringNav />,
    access: "media:get",
  },
  {
    route: "/control",
    translationKey: "control",
    nestedItems: [],
    icon: <IconControlNav />,
    access: "control:get",
  },
  {
    route: "/store",
    translationKey: "store",
    nestedItems: [],
    icon: <IconStoreNav />,
    access: "orders:create",
  },
  {
    route: "/users",
    translationKey: "users",
    nestedItems: [],
    icon: <IconUsers />,
    access: "super:manage|user:manage",
  },
];

export const ORGANIZATION_NAVIGATION_ITEMS: INavigationItem[] = [
  {
    route: "/products-portfolio/portfolio",
    translationKey: "productsPortfolio",
    nestedItems: [],
    icon: <IconProductsPortfolioNav />,
    access: "products:get",
  },
];

export const findItemKeyByRoute = (route: string) => {
  let item: INavigationItemSimple | undefined;
  [...NAVIGATION_ITEMS, ...ORGANIZATION_NAVIGATION_ITEMS].forEach(
    (navigationItem) => {
      if (navigationItem.route === route) {
        item = navigationItem;
        return;
      }
      if (navigationItem.nestedItems) {
        navigationItem.nestedItems.forEach((nestedItem) => {
          if (nestedItem.route === route) {
            item = nestedItem;
            return;
          }
        });
      }
    }
  );
  return item;
};

export const findSubKeyByRoute = (route: string) => {
  let item: INavigationItem | undefined;
  [...NAVIGATION_ITEMS, ...ORGANIZATION_NAVIGATION_ITEMS].forEach(
    (navigationItem) => {
      if (
        navigationItem.route === route ||
        navigationItem.nestedItems.find((i) => i.route === route)
      ) {
        item = navigationItem;
        return;
      }
    }
  );
  return item;
};
