import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  IOrder,
  EOrderStatus,
  IOrderShipping,
  IOrderPart,
  IOrdersListFilters,
} from "../types";
import { ColumnType } from "antd/lib/table";
import { actions } from "../slice";
import { AppDispatch } from "../../../app/store";
import { ApiStatuses, SortTypes } from "../../../app/types";
import { DATE_FORMAT, TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import { LMTable } from "../../../components/LMTable";
import { OrderStatus } from "../OrderStatus";
import { useLink } from "../../../components/LMLink";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconExport } from "../../../assets";
import { OrderPartsList } from "../OrderPartsList";
import { extendByDetails } from "../utils";
import styles from "./OrdersList.module.css";

interface Props {
  orders: IOrder[];
  filters: IOrdersListFilters;
  status: ApiStatuses;
  onExport: () => void;
}

export const OrdersList: FC<Props> = ({
  orders,
  filters,
  onExport,
  status,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useLink();
  const { t } = useTranslation("orders");

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  const handlePageSizeChange = (updatedSize: string) => {
    dispatch(actions.setPageSize(Number(updatedSize)));
    dispatch(actions.setPage(1));
  };

  const handleSortDateChange = (column: string, order: SortTypes) => {
    dispatch(actions.setDateSort(order));
  };

  const columns = useMemo(
    () => [
      {
        title: t("id"),
        dataIndex: "id",
        key: "id",
        fixed: "left",
        width: 120,
      },
      {
        title: t("time"),
        key: "created",
        dataIndex: "createdOn",
        width: 190,
        render: (createdOn: string) => {
          return (
            <>
              {format(new Date(createdOn), DATE_FORMAT)}
              <span className={styles.time}>
                &nbsp;
                {format(new Date(createdOn), TIME_NO_SECONDS_FORMAT)}
              </span>
            </>
          );
        },
        sorter: true,
      },
      {
        title: t("source"),
        key: "source",
        dataIndex: "internal",
        width: 80,
        render: (internal: boolean) => (internal ? t("user") : t("api")),
      },
      {
        title: t("status"),
        dataIndex: "status",
        key: "status",
        width: 150,
        render: (status: EOrderStatus) => <OrderStatus status={status} />,
      },
      {
        title: t("delivery"),
        dataIndex: "shippings",
        key: "shippings",
        width: 140,
        render: (shippings?: IOrderShipping[]) => (
          <OrderStatus
            status={
              shippings && shippings[shippings.length - 1]
                ? shippings[shippings.length - 1].status
                : undefined
            }
          />
        ),
      },
      {
        title: t("packs"),
        key: "packs",
        dataIndex: "packsCount",
        width: 100,
      },
      {
        title: t("parts"),
        key: "parts",
        dataIndex: "parts",
        width: 260,
        render: (parts: IOrderPart[]) =>
          parts ? (
            <OrderPartsList parts={parts as unknown as IOrderPart[]} />
          ) : (
            ""
          ),
      },
      ...extendByDetails(orders).detailsList.map((detailsItemName) => ({
        title: detailsItemName.title,
        key: detailsItemName.value,
        dataIndex: detailsItemName.value,
      })),
    ],
    [t, orders]
  ) as ColumnType<IOrder>[];

  const handleRowClick = (order: IOrder) => navigate(`/orders/${order.id}`);

  const onRow = (media: IOrder) => ({
    onClick: () => handleRowClick(media),
    style: { cursor: "pointer" },
  });

  return (
    <LMTable
      loading={status === ApiStatuses.loading}
      columns={columns}
      dataSource={extendByDetails(orders).ordersWithDetailsExpanded}
      total={{ title: t("total"), amount: filters.total }}
      action={
        <LMNewButton
          onClick={onExport}
          iconRight={<IconExport />}
          type="primary"
          small
        >
          {t("export")}
        </LMNewButton>
      }
      onChangePageSize={handlePageSizeChange}
      onChangePage={handlePageChange}
      onSortChange={handleSortDateChange}
      pageSize={filters.pageSize}
      page={filters.page}
      onRow={onRow}
      scroll={{ x: 1300 }}
    />
  );
};
