import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles, fetchSimpleList } from "../slice";
import { IRolesListItem } from "../types";
import { AppDispatch, RootState } from "../../../app/store";
import { LoaderWrapper } from "../../../components";
import { UserRoleItem } from "../UserRoleItem";
import styles from "./UserRoles.module.css";

export const UserRoles = () => {
  const dispatch = useDispatch<AppDispatch>();

  const getRoles = useCallback(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  const getUsers = useCallback(() => {
    dispatch(fetchSimpleList());
  }, [dispatch]);

  const roles = useSelector((state: RootState) => state.users.roles);
  const status = useSelector((state: RootState) => state.users.status);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div className={styles.container}>
      <LoaderWrapper status={status}>
        <div className={styles.roles}>
          {roles.map((role: IRolesListItem) => (
            <UserRoleItem
              key={role.role.id}
              userRole={role}
              onRefresh={getRoles}
            />
          ))}
        </div>
      </LoaderWrapper>
    </div>
  );
};
