import { FC } from "react";
import { Form } from "antd";
import { useSearchParams } from "react-router-dom";
import { UpdateUserForm } from "./UpdateUserForm";
import { FormModal } from "../../../components/FormModal";
import { IUserFormValues, IUser } from "../types";
import { CreateUserForm } from "./CreateUserForm";
import styles from "./UserFormModal.module.css";

interface Props {
  onRefresh: () => void;
  user?: IUser;
  onClose: () => void;
}

export const UserFormModal: FC<Props> = ({ onRefresh, user, onClose }) => {
  const [searchParams] = useSearchParams();
  const [createForm] = Form.useForm<IUserFormValues>();
  const [updateForm] = Form.useForm<IUserFormValues>();

  const handleSubmit = () => {
    if (user) {
      updateForm.submit();
      return;
    }
    createForm.submit();
  };

  return (
    <div className={styles.container}>
      <FormModal
        onCancel={onClose}
        open={!!searchParams.get("create") || !!user}
        onConfirm={handleSubmit}
        width={480}
        content={
          user ? (
            <UpdateUserForm
              form={updateForm}
              onRefresh={onRefresh}
              onClose={onClose}
              user={user}
            />
          ) : (
            <CreateUserForm
              form={createForm}
              onRefresh={onRefresh}
              onClose={onClose}
            />
          )
        }
      />
    </div>
  );
};
