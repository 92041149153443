import { Tooltip } from "antd";
import styles from "./UsersActions.module.css";
import { Can } from "../../casl";
import { IUser } from "../types";
import { FC } from "react";
import { IconEdit, IconMore, IconTrash } from "../../../assets";
import { Body2 } from "../../../components/Typography";
import { useTranslation } from "react-i18next";
import { LMIconButton } from "../../../components/LMIconButton";

interface Props {
  user: IUser;
  onSetUserToEdit: (user: IUser) => void;
  onSetUserToRemove: (user: IUser) => void;
}

export const UsersActions: FC<Props> = ({
  onSetUserToEdit,
  onSetUserToRemove,
  user,
}) => {
  const { t } = useTranslation("users");
  return (
    <Tooltip
      color="white"
      arrow={false}
      className={styles.tooltip}
      placement="bottomRight"
      title={
        <div className={styles.buttons}>
          <Can I="manage" a="user">
            <button
              className={styles.button}
              onClick={() => onSetUserToEdit(user)}
            >
              <IconEdit /> <Body2>{t("edit")}</Body2>
            </button>
          </Can>
          <Can I="manage" a="super">
            <button
              className={styles.button}
              onClick={() => onSetUserToRemove(user)}
            >
              <IconTrash /> <Body2>{t("delete")}</Body2>
            </button>
          </Can>
        </div>
      }
    >
      <LMIconButton>
        <IconMore />
      </LMIconButton>
    </Tooltip>
  );
};
