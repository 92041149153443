import { Tooltip } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  IconClearFilters,
  IconInfoBlue,
  IconPermissionsView,
  IconSearchSmall,
  IconUsersView,
} from "../../../assets";
import { LMNewInput } from "../../../components";
import { LMIconButton } from "../../../components/LMIconButton";
import { LMMultiSelect } from "../../../components/LMMultiSelect";
import { LMNewRadio } from "../../indexing/LMNewRadio";
import { EUsersViewType, EUserType, IUsersListFilters } from "../types";
import { Button2, H2 } from "../../../components/Typography";
import styles from "./UsersFilters.module.css";

interface Props {
  filters: IUsersListFilters;
  onSearchLastChanged: (value: boolean) => void;
}

export const UsersFilters: FC<Props> = ({ filters, onSearchLastChanged }) => {
  const { t } = useTranslation("users");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTypesChange = (value: string[]) => {
    onSearchLastChanged(false);
    searchParams.set("types", value.join(","));
    setSearchParams(searchParams);
  };

  const handleSearchChange = (evt: SyntheticEvent) => {
    onSearchLastChanged(true);
    const target = evt.target as HTMLInputElement;
    searchParams.set("search", target.value);
    setSearchParams(searchParams);
  };

  const handleClearFilters = () => {
    onSearchLastChanged(false);
    setSearchParams({ viewType: filters.viewType || EUsersViewType.users });
  };

  const handleSetViewType = (value: SegmentedValue) => {
    onSearchLastChanged(false);
    searchParams.set("viewType", value as string);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      {filters.viewType === EUsersViewType.users ? (
        <>
          <Tooltip
            trigger={["focus"]}
            color="white"
            title={
              <Button2 className={styles.hint}>
                <IconInfoBlue />
                {t("searchHint")}
              </Button2>
            }
            placement="bottom"
            overlayClassName={styles["hint-overlay"]}
          >
            <LMNewInput
              placeholder={t("searchPlaceholder")}
              onChange={handleSearchChange}
              icon={<IconSearchSmall />}
              value={searchParams.get("search") || ""}
            />
          </Tooltip>
          <div className={styles.status}>
            <LMMultiSelect
              value={filters.typeFilter}
              onChange={handleTypesChange}
              placeholder={t("typePlaceholder")}
              items={Object.values(EUserType).map((type) => ({
                name: t(type),
                value: type,
              }))}
            />
          </div>
          <Tooltip placement="top" title={t("resetFilters")}>
            <LMIconButton color="white" onClick={handleClearFilters}>
              <IconClearFilters />
            </LMIconButton>
          </Tooltip>
        </>
      ) : (
        <H2>{t("permissions")}</H2>
      )}
      <div className={styles["switch-container"]}>
        <LMNewRadio
          onChange={handleSetViewType}
          size="large"
          options={[
            {
              label: (
                <div className={styles["switch-item-container"]}>
                  <IconUsersView />
                  {t("users")}
                </div>
              ),
              value: EUsersViewType.users,
            },
            {
              label: (
                <div className={styles["switch-item-container"]}>
                  <IconPermissionsView />
                  {t("permissions")}
                </div>
              ),
              value: EUsersViewType.permissions,
            },
          ]}
          value={filters.viewType || EUsersViewType.users}
        />
      </div>
    </div>
  );
};
