import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import {
  fetchUsers,
  UsersFilters,
  usersActions,
  UsersList,
  EUsersViewType,
} from "../../modules/users";
import styles from "./Users.module.css";
import { UserRoles } from "../../modules/users/UserRoles";

export const Users: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const [isSearchLastChange, setIsSearchLastChange] = useState(false);

  const { status, filters, list } = useSelector((state: RootState) => {
    return state.users;
  });

  useEffect(() => {
    const search = searchParams.get("search") || "";
    const types = searchParams.get("types") || "";
    if (search && search.length < 2 && isSearchLastChange) return;
    dispatch(
      usersActions.setFilters({
        search,
        types,
        viewType: searchParams.get("viewType") || EUsersViewType.users,
      })
    );
  }, [searchParams, dispatch, isSearchLastChange]);

  const getUsers = useCallback(() => {
    dispatch(fetchUsers(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    getUsers();
  }, [getUsers, filters]);

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <UsersFilters
          filters={filters}
          onSearchLastChanged={setIsSearchLastChange}
        />
      </div>
      <div className={styles.list}>
        {filters.viewType === EUsersViewType.users ? (
          <UsersList
            onRefresh={getUsers}
            users={list}
            status={status}
            filters={filters}
          />
        ) : (
          <UserRoles />
        )}
      </div>
    </div>
  );
};
