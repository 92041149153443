import { FC, memo, useCallback, useContext, useEffect, useState } from "react";
import { MenuProps } from "rc-menu";
import { Menu } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  findItemKeyByRoute,
  findSubKeyByRoute,
  NAVIGATION_ITEMS,
  ORGANIZATION_NAVIGATION_ITEMS,
} from "../utils";
import { useTranslation } from "react-i18next";
import { INavigationItem, INavigationItemSimple } from "../types";
import { AbilityContext } from "../../casl";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import styles from "./Navigation.module.css";

interface Props {
  collapsed: boolean;
  isOrganization?: boolean;
}

const getItems = (isOrganization: boolean) => {
  if (isOrganization) return ORGANIZATION_NAVIGATION_ITEMS;
  return NAVIGATION_ITEMS;
};

export const Navigation: FC<Props> = memo(
  ({ collapsed, isOrganization = false }) => {
    const params = useParams();
    const [key, setKey] = useState("");
    const ability = useContext(AbilityContext);
    const [openKey, setOpenKey] = useState("");
    const { t } = useTranslation("navigation");
    const location = useLocation();

    const { selectedSystem } = useSelector((state: RootState) => {
      return state.systems;
    });

    const getChildren = (items: INavigationItemSimple[]) => {
      if (items.length === 0) return;
      return items.map((item) => ({
        label: <Link to={getRoute(item.route)}>{t(item.translationKey)}</Link>,
        key: item.translationKey,
      }));
    };

    const getAbilityAccess = (access: string) => {
      const accessItems = access.split(":");
      const module = accessItems[0];
      const action = accessItems[1];
      return ability.can(action, module);
    };

    const getAccess = (access: string) => {
      if (access === "all") return true;
      if (access.includes("|")) {
        const items = access.split("|");
        let hasAccess = false;
        items.forEach((item) => {
          if (getAbilityAccess(item)) {
            hasAccess = true;
          }
        });
        return hasAccess;
      }
      return getAbilityAccess(access);
    };

    const filterItems = (item: INavigationItem | INavigationItemSimple) => {
      if (
        item.route === "/media/library" &&
        selectedSystem?.serialNumber.toLowerCase() === "mock"
      ) {
        return false;
      }

      return getAccess(item.access);
    };

    const getPrefix = useCallback(() => {
      if (params.client) {
        return `/${params.client}/${params.systemId}`;
      }
      return `/${params.systemId}`;
    }, [params]);

    const getRoute = (route: string) => {
      if (!route) return route;
      return `${getPrefix()}${route}`;
    };

    const items: MenuProps["items"] = getItems(isOrganization)
      .filter(filterItems)
      .map((item) => {
        return {
          ...item,
          nestedItems: item.nestedItems.filter(filterItems),
        };
      })
      .map((item) => {
        return {
          label: (
            <Link
              style={{ pointerEvents: item.route ? "all" : "none" }}
              to={getRoute(item.route)}
            >
              {t(item.translationKey)}
            </Link>
          ),
          key: item.translationKey,
          icon: item.icon,
          children: getChildren(item.nestedItems),
        };
      });

    const handleOpenKeyChange = (keys: string[]) => {
      const keysReversed = [...keys].reverse();
      const key = keysReversed.find((k) => {
        return NAVIGATION_ITEMS.find(
          (i) => i.translationKey === k && i.nestedItems.length > 0
        );
      });
      setOpenKey(key || "");
    };

    useEffect(() => {
      const activeItem = findItemKeyByRoute(
        location.pathname.replace(getPrefix(), "")
      );
      if (!activeItem) return;
      setKey(activeItem.translationKey);
    }, [location.pathname, getPrefix]);

    useEffect(() => {
      if (collapsed) return;
      const activeSub = findSubKeyByRoute(location.pathname);
      if (!activeSub || activeSub.nestedItems.length === 0) return;
      setOpenKey(activeSub.translationKey);
    }, [collapsed, location.pathname]);

    return (
      <Menu
        prefix="test"
        theme="light"
        onOpenChange={handleOpenKeyChange}
        openKeys={collapsed ? undefined : [openKey]}
        items={items}
        mode="inline"
        className={styles.menu}
        selectedKeys={[key]}
      />
    );
  }
);
